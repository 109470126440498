<script>
  export let style;
</script>

<div {style}>
  <slot />
</div>

<style>
  div {
    --em: 0.75rem;
    display: block;
    z-index: 9999;
    pointer-events: none;
    position: sticky;
    left: 0;
    height: var(--baseline);
    font-size: var(--em);
    line-height: var(--baseline);
    color: hsl(var(--secondary));
    padding: calc(var(--baseline)/2) var(--baseline);

    grid-column: var(--col) / span var(--span);
    grid-row: var(--row);
  }
</style>
