<script>
  import { _ } from "./util";
  let data = {console: ""};
  export {data as log};
  let debugging = true;
  let format = (v) => JSON.stringify(v, null, " ").replace(/\"/g, "");

  $: log = Object.entries(data);
</script>

<svelte:window on:keydown={(e) => { if (e.key === ".") if (debugging == true) { debugging = false } else { debugging = true } }} />

<console class:show={debugging}>
  <div class="log">
    {#each log as entry, i}
      <div class="name">{entry[0]}</div>
      <div class="data">{format(entry[1])}</div>
    {/each}
  </div>
</console>

<style>

  console {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    max-height: 25vh;
    background: rgba(0,0,0,.5);
    color: hsl(225,79%,88%);
    z-index: 999999;
    overflow-x: scroll;
    overflow-y: scroll;
    padding: 1rem;
    pointer-events: none;
  }

  console.show {
    display: block;
  }

  .log {
    display: grid;
    grid-template-columns: min-content auto;
    min-width: 0;
    gap: 1ch 1ch;
    font-family: var(--monoFont);
  }

  .name {
    font-weight: 650;
    color: hsl(37,100%,70%);
  }

</style>
