<script>
  import App from "./App.svelte";
  let debug = false;

  // let shortcuts = (e) => {
  //   if (e.key === ".") debug = (debug == false) ? true : false;
  // }
</script>

<main FRAME {debug}>
  <App />
</main>

<!-- <svelte:window
  on:keydown={shortcuts}
  on:contextmenu={(e) => e.preventDefault()}
  /> -->

<style>

</style>
