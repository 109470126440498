<script>
  import { onMount } from 'svelte';
  import Tone from "tone";
  import { Note } from "./util";
  export let triggered = false;
  export let style = null;
  export let note = null;
  let playing = false;

  let play = (note) => note.ref.play();
  // $: if (triggered) { playing = true; setTimeout(() => playing = false, 100) }
</script>

<button
  class={note.transformation && "expanded"}
  on:mousedown={(e) => e.button !== 2 ? play(note) : e}
  on:focus={play(note)}
  on:click
  on:mousedown
  on:mouseover
  on:mouseenter
  on:mouseleave
  playing={triggered}
  style={style}>
  <slot />
</button>

<style>

  button {
    z-index: var(--row);
    display: block;
    background-color: hsla(var(--accent), 0.6);
    color: hsla(var(--primary), 0.8);
    font-size: 0.75rem;
    font-weight: 420;
    border-radius: 0;
    cursor: default;
    box-shadow: inset 0 0 0 1px hsla(var(--accent), 1);
    /* transition: opacity 500ms ease-in-out; */
    /* opacity: 1; */

    grid-column: var(--col) / span var(--span);
    grid-row: var(--row);
    text-align: center;
    position: relative;
  }

  button.expanded {
    z-index: calc(var(--row) - 1);
    --accent: var(--hue), calc(var(--heat) * 20%), 52%;
    /* --primary: var(--hue), 100%, 99.6%; */
    /* --secondary: var(--hue), 3%, 57%; */
    /* --tertiary: var(--hue), 2%, 28%; */
    /* --quaternary: var(--hue), 3%, 17%; */
    /* --quinary: var(--hue), 0%, 0%; */
  }

  button:before {
    z-index: var(--row);
    display: block;
    position: absolute;
    content: '';
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    transform: scale(0.5);
    box-shadow: inset 0 0 0 1px hsl(var(--quinary));
    pointer-events: none;
  }

  button:hover,
  button:focus,
  button[playing="true"] {
    z-index: 999;
  }

  button:hover {
    background-color: hsla(var(--accent), 0.8);
  }

  button:focus,
  button[playing="true"] {
    background-color: hsla(var(--accent), 1);
    color: hsl(var(--primary));
    box-shadow: inset 0 0 0 1px hsla(var(--primary), 0.4);
    outline: 1px solid hsla(var(--quinary), 0.2);
  }

  button[playing="true"] {
    margin: 1px 2px;
  }

</style>
