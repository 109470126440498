<script>
  let notes;
  import Tone from "tone";
  import { onMount } from 'svelte';
  import { _, Note, data as defaultNotes } from "./util";
  import Input from "./Input.svelte";
  import PianoRoll from "./PianoRoll.svelte";

  let B44toSemiquaver = (b44, mod = true) => {
    if (mod) { b44[0]; b44[1]--; b44[2]--; }

    let bar       = b44[0] * (16/1);
    let beat      = b44[1] * (16/4);
    let sixteenth = b44[2] * (16/16);
    let sq        = bar + beat + sixteenth;

    return sq;
  }

  const voice = new Tone.Sampler({
    "A0" : "A0.[mp3|ogg]",
    "C1" : "C1.[mp3|ogg]",
    "D#1" : "Ds1.[mp3|ogg]",
    "F#1" : "Fs1.[mp3|ogg]",
    "A1" : "A1.[mp3|ogg]",
    "C2" : "C2.[mp3|ogg]",
    "D#2" : "Ds2.[mp3|ogg]",
    "F#2" : "Fs2.[mp3|ogg]",
    "A2" : "A2.[mp3|ogg]",
    "C3" : "C3.[mp3|ogg]",
    "D#3" : "Ds3.[mp3|ogg]",
    "F#3" : "Fs3.[mp3|ogg]",
    "A3" : "A3.[mp3|ogg]",
    "C4" : "C4.[mp3|ogg]",
    "D#4" : "Ds4.[mp3|ogg]",
    "F#4" : "Fs4.[mp3|ogg]",
    "A4" : "A4.[mp3|ogg]",
    "C5" : "C5.[mp3|ogg]",
    "D#5" : "Ds5.[mp3|ogg]",
    "F#5" : "Fs5.[mp3|ogg]",
    "A5" : "A5.[mp3|ogg]",
    "C6" : "C6.[mp3|ogg]",
    "D#6" : "Ds6.[mp3|ogg]",
    "F#6" : "Fs6.[mp3|ogg]",
    "A6" : "A6.[mp3|ogg]",
    "C7" : "C7.[mp3|ogg]",
    "D#7" : "Ds7.[mp3|ogg]",
    "F#7" : "Fs7.[mp3|ogg]",
    "A7" : "A7.[mp3|ogg]",
    "C8" : "C8.[mp3|ogg]"
  }, {
    "release" : 1,
    "baseUrl" : "https://tonejs.github.io/examples/audio/salamander/"
  }).toMaster();

  let MidiNote = function(data) {
    data = {
      head: _.take(data,4),
      body: _.dropRight(_.drop(data,4),4),
      tail: _.takeRight(data,4) };
    let note = Note.get(
      data.body[2].replace("♯","#") + data.body[3] );
    // let dur = ;

    this.active = false;
    this.UID = _.uniqueId(note.name);
    this.ID = [note.chroma,note.oct].join("");
    this.empty = note.empty;
    this.acc = note.acc;
    this.alt = note.alt;
    this.chroma = note.chroma;
    this.coord = note.coord;
    this.freq = note.freq;
    this.height = note.height;
    this.letter = note.letter;
    this.midi = note.midi;
    this.name = note.name;
    this.oct = note.oct;
    this.pc = note.pc;
    this.step = note.step;
    this.pos = _.map(_.take(data.head,3), v => _.toInteger(v));
    this.dur = _.reverse(_.map(_.take(data.tail,3), v => _.toInteger(v)));
    this.play = () => voice.triggerAttackRelease(Note.simplify(note.pc) + note.oct, _.map(_.take(data.tail,3), v => _.toInteger(v)).join(":"));
  }

  let parseMidi = function(midi) {
    midi = midi.split("\n");
    midi = _.map(midi, (noteData) => _.words(noteData));
    midi = _.filter(midi, (noteData) => noteData.length);
    midi = _.map(midi, (noteData) => new MidiNote(noteData));
    notes = midi;
  }

  let handlePaste = function(e) {
    let clipboardData, pastedData;
    e.stopPropagation();
    e.preventDefault();
    clipboardData = e.clipboardData || window.clipboardData;
    pastedData = clipboardData.getData('Text');
    if (_.includes(pastedData, `1 	Note	 	1`)) parseMidi(pastedData)
  }

  $: noteNames = _.map(notes, note => note.name).join(" ");

  onMount(() => { parseMidi(defaultNotes) });
</script>

<Input
  label="Melody"
  placeholder="Paste MIDI notes"
  bind:value={noteNames}
  on:paste={handlePaste}
  />
<PianoRoll {notes} {voice} />
