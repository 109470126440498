<script>
  import { onMount } from 'svelte';
  import Tone from "tone";
  import { _, Note, Interval } from "./util";
  import Console from "./Console.svelte";
  import MidiNote from "./MidiNote.svelte";
  import Legend from "./Legend.svelte";
  export let notes;
  export let voice;

  let BPM = 190;
  let newArray = n => [...Array(n).keys()];
  let rev = arr => _.reverse(arr);
  let vset = (p,v) => `--${p}: ${v};`;
  let vprops = obj => _.map(obj, (v,k) => vset(k, v)).join("");
  let vget = (p) => `var(--${p})`;
  let parseDuration = arr => _.sum( _.map(arr, (div, i) => div * Math.pow(phrase.signature, i) ) );
  let up = (n,i) => Note.transpose(n, i);
  let down = (n,i) => Note.transpose(n, `-${i}`);
  let arrayToInteger = (arr) => _.map(arr, (v) => _.toInteger(v));
  let parseToneTime = (t) => [ t[0] % phrase.measures, t[1] + 1, Math.floor(t[2]) + 1 ];
  let toneTime = (t) => t.join(":");
  let arrayTime = (t) => t.split(":");
  let semiquaverFromCursorX = (left, max, x, unit = 12) => {
    return Math.floor(max / unit) - Math.floor((max / unit) - (x / unit)) + Math.round(left / unit)
  }

  let B44toSemiquaver = (b44, mod = true) => {
    if (mod) { b44[0]; b44[1]--; b44[2]--; }

    let bar       = b44[0] * (16/1);
    let beat      = b44[1] * (16/4);
    let sixteenth = b44[2] * (16/16);
    let sq        = bar + beat + sixteenth;

    return sq;
  }

  let semiquaverToB44 = (qua = 2) => {
    let BAR=16, BEA=4, SIX=1,
        barM,   beaM,  sixM,
        bar,    bea,   six;

    barM = qua % BAR;
    beaM = qua % BEA;
    sixM = qua % SIX;
    bar  = (qua - barM) / BAR;
    bea  = (barM - beaM) / BEA;
    six  = (beaM - sixM) / SIX;

    return [bar, bea+1, six+1];
  }

  let absB44 = (b44) => {
    Tone.Transport.bpm.value = BPM;

    let bar       = Tone.TimeBase(1, "m").toSeconds();
    let beat      = bar/4;
    let sixteenth = beat/4;
    let seconds   = (b44[0] * bar)
                  + ((b44[1]-1) * beat)
                  + ((b44[2]-1) * sixteenth);

    return seconds;
  }

  let scrollX = 0;
  let position = 0;
  let c = { x: 0, y: 0 };

  $: phrase = notes ? {
    octMax: _.maxBy(notes, note => note.oct ).oct,
    octMin: _.minBy(notes, note => note.oct ).oct,
    octaves: _.maxBy(notes, note => note.oct ).oct - _.minBy(notes, note => note.oct ).oct,
    measures: _.maxBy(notes, note => note.pos[0] ).pos[0]+1,
    semitones: 12,
    bpm: BPM,
    signature: 4,
    divisions: 2,
    ticks: Math.pow(4, 2),
  } : null;

  $: log = {
    Phrase: phrase,
    scrollX: scrollX,
    Cursor: cursor,
  };

  let myNote;

  $: Events = _.map(
    notes, (note) => {
      let p = note.pos;
      return {
        "time": toneTime([p[0], p[1]-1, p[2]-1]),
        "callback": note.play, "ref": note,
        "col": (phrase.ticks * note.pos[0]+1) + ((note.pos[1]-1) * 4)  + ((note.pos[2]-1) * 1),
        "row": ( (phrase.octaves * 12) - ((note.oct - phrase.octMin) * 12) ) + (12 - note.chroma),
  } });

  $: cursor = {
    position: semiquaverFromCursorX(scrollX, c.max, c.x) || 0,
    B44: semiquaverToB44(
     (semiquaverFromCursorX(scrollX, c.max, c.x) || 1) - 1
    ),
  }

  let updateCursor = (e) => {
    c.x = e.clientX;
    c.y = e.clientY;
    c.max = window.outerWidth;
  }

  let updatePlayback = (e) => { if (e.code === "Space") {
    e.preventDefault();
    let started = Tone.Transport.state === "started";

    if (started) {
      Tone.Transport.cancel(0)
    } else {
      Tone.Transport.scheduleRepeat((t) => { position++ }, "16n", 0)
      _.forEach(Events, e => Tone.Transport.schedule(e.callback, e.time))
    }
    Tone.Transport.toggle()
  }}

  Tone.Transport.bpm.value = BPM;
</script>

<svelte:window on:keydown={updatePlayback}/>
<!-- <Console {log}/> -->

<roll on:mousemove={updateCursor} on:click={(e) => {position = cursor.position-1; Tone.Transport.position = absB44(cursor.B44);}}>
  <phrase on:scroll={(e) => scrollX = e.target.scrollLeft} root style={vprops(phrase)}>
    {#if notes}
      <x-cursor
        style={vprops({
          span: 1,
          col: cursor.position,
          row: 1,
        })} />
      <playhead
        style={vprops({
          span: 1,
          col: position+1,
          row: 1,
        })} />

      {#each newArray(phrase.octaves+1) as octave, i}
        <Legend
          style={vprops({
            span: 2,
            col: 1,
            row: ((i+1) * 12) - 11,
          })}>
          {phrase.octMax - i}
        </Legend>
      {/each}
      {#each Events as note}
        <MidiNote
          on:mousedown={(e) => e.button === 2 ? e : myNote = note}
          triggered={_.inRange(position+1, note.col, note.col + parseDuration(note.ref.dur))}
          style={vprops({
            hue: vget("blue"),
            span: parseDuration(note.ref.dur),
            col: note.col,
            row: note.row,
          })}
          {voice}
          {note}>
          {Note.simplify(note.ref.pc)}
        </MidiNote>
      {/each}
    {/if}
  </phrase>
</roll>

<style>

  [root] {
    --H: var(--lineHeight);
    --W: var(--baseline);
    --octaveH: calc(var(--H) * var(--semitones));
    --rollH: calc((var(--octaveH) * 2) + var(--H));
    --sumTicks: calc(var(--ticks) * var(--measures));

    --X1: hsla(var(--primary), 0.05);
    --x1: hsla(var(--quinary), 0.00);
    --X2: hsla(var(--tertiary), 0.25);
    --x2: hsla(var(--quinary), 0.00);

    --Y1: hsla(var(--quinary), 0.00);
    --y1: hsla(var(--tertiary), 0.05);
    --Y2: hsla(var(--quinary), 0.00);
    --y2: hsla(var(--primary), 0.05);

    --measure: calc(var(--W) * 64);
    --semibreve: calc(var(--measure) / 4);
    --minim: calc(var(--semibreve) / 2);
    --crotchet: calc(var(--minim) / 2);
    --quaver: calc(var(--crotchet) / 2);
    --semiquaver: calc(var(--quaver) / 2);

    --octave: calc(var(--H) * 12);
    --tone: calc(var(--octave) / 2);
    --semitone: calc(var(--tone) / 3);
    --quartertone: calc(var(--semitone) / 2);

    --x: var(--measure);
    --y: var(--octave);

    --divider: .5px;
  }

  phrase {
    min-height: var(--pianoRollHeight);
    max-height: calc(var(--pianoRollHeight) - 1px);
    overflow-y: scroll;
    overflow-x: scroll;
    display: grid;
    /* grid-template-rows: repeat(calc(var(--semitones) * (var(--octaves) + 1)), var(--H)); */
    /* grid-template-columns: repeat(var(--sumTicks), var(--W)); */
    grid-auto-rows: var(--H);
    grid-auto-columns: var(--W);
    position: relative;

    background-image:
      repeating-linear-gradient(0deg,
        var(--Y1),
        var(--Y1) var(--divider),
        var(--y1) 0,
        var(--y1) calc(var(--octave) / 2),
        var(--Y2) calc(var(--octave) / 2),
        var(--Y2) calc(var(--octave) / 2 + var(--divider)),
        var(--y2) calc(var(--octave) / 2 + var(--divider)),
        var(--y2) var(--octave)),
      repeating-linear-gradient(0deg,
        var(--Y1),
        var(--Y1) var(--divider),
        var(--y1) 0,
        var(--y1) calc(var(--tone) / 2),
        var(--Y2) calc(var(--tone) / 2),
        var(--Y2) calc(var(--tone) / 2 + var(--divider)),
        var(--y2) calc(var(--tone) / 2 + var(--divider)),
        var(--y2) var(--tone)),
      repeating-linear-gradient(0deg,
        var(--Y1),
        var(--Y1) var(--divider),
        var(--y1) 0,
        var(--y1) calc(var(--semitone) / 2),
        var(--Y2) calc(var(--semitone) / 2),
        var(--Y2) calc(var(--semitone) / 2 + var(--divider)),
        var(--y2) calc(var(--semitone) / 2 + var(--divider)),
        var(--y2) var(--semitone)),
      repeating-linear-gradient(90deg,
        var(--X1),
        var(--X1) var(--divider),
        var(--x1) 0,
        var(--x1) calc(var(--measure) / 2),
        var(--X2) calc(var(--measure) / 2),
        var(--X2) calc(var(--measure) / 2 + var(--divider)),
        var(--x2) calc(var(--measure) / 2 + var(--divider)),
        var(--x2) var(--measure)),
      repeating-linear-gradient(90deg,
        var(--X1),
        var(--X1) var(--divider),
        var(--x1) 0,
        var(--x1) calc(var(--semibreve) / 2),
        var(--X2) calc(var(--semibreve) / 2),
        var(--X2) calc(var(--semibreve) / 2 + var(--divider)),
        var(--x2) calc(var(--semibreve) / 2 + var(--divider)),
        var(--x2) var(--semibreve)),
      repeating-linear-gradient(90deg,
        var(--X1),
        var(--X1) var(--divider),
        var(--x1) 0,
        var(--x1) calc(var(--minim) / 2),
        var(--X2) calc(var(--minim) / 2),
        var(--X2) calc(var(--minim) / 2 + var(--divider)),
        var(--x2) calc(var(--minim) / 2 + var(--divider)),
        var(--x2) var(--minim)),
      repeating-linear-gradient(90deg,
        var(--X1),
        var(--X1) var(--divider),
        var(--x1) 0,
        var(--x1) calc(var(--crotchet) / 2),
        var(--X2) calc(var(--crotchet) / 2),
        var(--X2) calc(var(--crotchet) / 2 + var(--divider)),
        var(--x2) calc(var(--crotchet) / 2 + var(--divider)),
        var(--x2) var(--crotchet)),
      repeating-linear-gradient(90deg,
        var(--X1),
        var(--X1) var(--divider),
        var(--x1) 0,
        var(--x1) calc(var(--quaver) / 2),
        var(--X2) calc(var(--quaver) / 2),
        var(--X2) calc(var(--quaver) / 2 + var(--divider)),
        var(--x2) calc(var(--quaver) / 2 + var(--divider)),
        var(--x2) var(--quaver));
    background-repeat: repeat;
    background-size: var(--x) var(--y);
    background-position: 0 calc(var(--divider) / 2);
    background-attachment: local;
  }

  roll {
    display: block;
    position: relative;
  }

  roll:before {
    display: block;
    left: 0;
    position: absolute;
    content: '';
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    transform: scale(0.5);
    box-shadow: inset 0 0 0 1px hsl(var(--focus-color, var(--quinary)));
    pointer-events: none;
  }

  roll:focus-within {
    --focus-color: var(--accent);
  }

  playhead,
  x-cursor {
    z-index: var(--row);
    display: block;
    grid-column: var(--col) / span var(--span);
    grid-row: var(--row);
    text-align: center;
    position: relative;
  }

  playhead:after,
  x-cursor:after {
    display: block;
    position: absolute;
    content: '';
    height: calc(var(--octaveH) * calc(var(--octaves) + 1));
    top: 0;
    left: 0;
    right: 0;
  }

  playhead:after {
    background: hsla(var(--primary), 0.125);
  }

  x-cursor:after {
    /* background: hsla(199,78%,49%, 0.125); */
    border-right: 1px dotted hsl(var(--secondary));
    z-index: 9999999999;
  }

  dom-loaded {
    display: none;
  }

</style>
