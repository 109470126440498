const melody = [
  "0  1  1      1 	Note	 	1	 	D3	 	  77	        1  0  0      0 ",
  "1  1  1      1 	Note	 	1	 	C3	 	  77	        1  0  0      0 ",
  "2  1  1      1 	Note	 	1	 	A♯2	 	  77	        1  0  0      0 ",
  "3  2  1      1 	Note	 	1	 	D3	 	  77	        0  1  2      0 ",
  "3  2  3      1 	Note	 	1	 	D♯3	 	  77	        0  1  2      0 ",
  "3  3  1      1 	Note	 	1	 	D3	 	  77	        0  1  2      0 ",
  "3  3  3      1 	Note	 	1	 	C3	 	  77	        0  1  2      0 ",
  "3  4  1      1 	Note	 	1	 	A♯2	 	  77	        0  1  2      0 ",
  "4  1  1      1 	Note	 	1	 	G2	 	  77	        1  0  0      0 ",
  "5  1  1      1 	Note	 	1	 	A2	 	  77	        0  3  0      0 ",
  "6  1  1      1 	Note	 	1	 	A♯2	 	  77	        1  0  0      0 ",
  "8  1  1      1 	Note	 	1	 	D3	 	  77	        0  2  2      0 ",
  "8  3  3      1 	Note	 	1	 	G3	 	  77	        0  2  0      0 ",
  "9  1  3      1 	Note	 	1	 	A3	 	  77	        0  2  2      0 ",
  "9  4  1      1 	Note	 	1	 	F3	 	  77	        0  1  2      0 ",
  "10  1  1      1 	Note	 	1	 	D3	 	  77	        1  0  0      0 ",
  "11  2  1      1 	Note	 	1	 	D3	 	  77	        0  1  2      0 ",
  "11  2  3      1 	Note	 	1	 	D♯3	 	  77	        0  1  2      0 ",
  "11  3  1      1 	Note	 	1	 	D3	 	  77	        0  1  2      0 ",
  "11  3  3      1 	Note	 	1	 	C3	 	  77	        0  1  2      0 ",
  "11  4  1      1 	Note	 	1	 	A♯2	 	  77	        0  1  2      0 ",
  "12  1  1      1 	Note	 	1	 	G3	 	  77	        0  2  2      0 ",
  "12  3  3      1 	Note	 	1	 	A♯2	 	  77	        0  1  2      0 ",
  "12  3  3      1 	Note	 	1	 	A♯2	 	  77	        0  1  2      0 ",
  "12  4  1      1 	Note	 	1	 	C3	 	  77	        0  1  2      0 ",
  "12  4  3      1 	Note	 	1	 	A2	 	  77	        0  3  2      0 ",
  "13  4  1      1 	Note	 	1	 	G2	 	  77	        0  1  2      0 ",
  "13  4  3      1 	Note	 	1	 	A2	 	  77	        0  1  2      0 ",
  "14  1  1      1 	Note	 	1	 	A♯2	 	  77	        0  3  0      0 ",
];

const sorry = [
  "0  1  1      1 	Note	 	1	 	A♯3	 	  37	        0  2  0      0 ",
  "0  3  3      1 	Note	 	1	 	G4	 	  37	        0  1  2      0 ",
  "1  1  3      1 	Note	 	1	 	A4	 	  37	        0  1  2      0 ",
  "1  4  1      1 	Note	 	1	 	F4	 	  37	        0  2  0      0 ",
  "2  1  1      1 	Note	 	1	 	D4	 	  37	        0  2  0      0 ",
  "3  2  1      1 	Note	 	1	 	D4	 	  37	        0  1  0      0 ",
  "3  2  3      1 	Note	 	1	 	D♯4	 	  37	        0  1  0      0 ",
  "3  3  1      1 	Note	 	1	 	D4	 	  37	        0  1  0      0 ",
  "3  3  3      1 	Note	 	1	 	C4	 	  37	        0  1  0      0 ",
  "3  4  1      1 	Note	 	1	 	A♯3	 	  37	        0  1  0      0 ",
  "4  1  1      1 	Note	 	1	 	G4	 	  37	        0  2  0      0 ",
  "4  3  3      1 	Note	 	1	 	A♯3	 	  37	        0  1  2      0 ",
  "4  4  1      1 	Note	 	1	 	C4	 	  37	        0  1  2      0 ",
  "4  4  3      1 	Note	 	1	 	A3	 	  37	        0  1  2      0 ",
  "5  4  1      1 	Note	 	1	 	A♯3	 	  37	        0  2  0      0 ",
  "5  4  3      1 	Note	 	1	 	A♯3	 	  37	        0  2  0      0 ",
  "0  1  1      1 	Note	 	1	 	D3	 	  77	        1  0  0      0 ",
  "1  1  1      1 	Note	 	1	 	C3	 	  77	        1  0  0      0 ",
  "2  1  1      1 	Note	 	1	 	A♯2	 	  77	        1  0  0      0 ",
  "3  2  1      1 	Note	 	1	 	D3	 	  77	        0  1  2      0 ",
  "3  2  3      1 	Note	 	1	 	D♯3	 	  77	        0  1  2      0 ",
  "3  3  1      1 	Note	 	1	 	D3	 	  77	        0  1  2      0 ",
  "3  3  3      1 	Note	 	1	 	C3	 	  77	        0  1  2      0 ",
  "3  4  1      1 	Note	 	1	 	A♯2	 	  77	        0  1  2      0 ",
  "4  1  1      1 	Note	 	1	 	G2	 	  77	        1  0  0      0 ",
  "5  1  1      1 	Note	 	1	 	A2	 	  77	        1  0  0      0 ",
  "6  1  1      1 	Note	 	1	 	A♯2	 	  77	        1  0  0      0 ",
  "8  1  1      1 	Note	 	1	 	D3	 	  77	        0  2  2      0 ",
  "8  3  3      1 	Note	 	1	 	G3	 	  77	        0  2  0      0 ",
  "9  1  3      1 	Note	 	1	 	A3	 	  77	        0  2  2      0 ",
  "9  4  1      1 	Note	 	1	 	F3	 	  77	        0  1  2      0 ",
  "10  1  1      1 	Note	 	1	 	D3	 	  77	        1  0  0      0 ",
  "11  2  1      1 	Note	 	1	 	D3	 	  77	        0  1  2      0 ",
  "11  2  3      1 	Note	 	1	 	D♯3	 	  77	        0  1  2      0 ",
  "11  3  1      1 	Note	 	1	 	D3	 	  77	        0  1  2      0 ",
  "11  3  3      1 	Note	 	1	 	C3	 	  77	        0  1  2      0 ",
  "11  4  1      1 	Note	 	1	 	A♯2	 	  77	        0  1  2      0 ",
  "12  1  1      1 	Note	 	1	 	G3	 	  77	        0  2  2      0 ",
  "12  3  3      1 	Note	 	1	 	A♯2	 	  77	        0  1  2      0 ",
  "12  3  3      1 	Note	 	1	 	A♯2	 	  77	        0  1  2      0 ",
  "12  4  1      1 	Note	 	1	 	C3	 	  77	        0  1  2      0 ",
  "12  4  3      1 	Note	 	1	 	A2	 	  77	        0  3  2      0 ",
  "13  4  1      1 	Note	 	1	 	G2	 	  77	        0  1  2      0 ",
  "13  4  3      1 	Note	 	1	 	A2	 	  77	        0  1  2      0 ",
  "14  1  1      1 	Note	 	1	 	A♯2	 	  77	        0  3  0      0 ",
]

const trapeze = [
  "0  3  4      1 	Note	 	1	 	D4	 	 103	        1  0  0      0 ",
  "1  3  4      1 	Note	 	1	 	D4	 	 103	        0  0  3    67 ",
  "1  4  4      1 	Note	 	1	 	E4	 	 103	        0  0  1      0 ",
  "2  1  1      1 	Note	 	1	 	D4	 	 103	        0  0  3      0 ",
  "2  1  4      1 	Note	 	1	 	C♯4	 	 103	        0  2  3    24 ",
  "2  4  4      1 	Note	 	1	 	D4	 	 103	        0  0  1      0 ",
  "3  1  1      1 	Note	 	1	 	C♯4	 	 103	        0  0  3      0 ",
  "3  1  4      1 	Note	 	1	 	B3	 	 103	        0  2  0    80 ",
  "3  4  4      1 	Note	 	1	 	D4	 	 103	        0  0  3  102 ",
  "4  1  4      1 	Note	 	1	 	C♯4	 	 103	        0  1  0      0 ",
  "4  2  4      1 	Note	 	1	 	B3	 	 103	        0  0  1      0 ",
  "4  3  1      1 	Note	 	1	 	A3	 	 103	        0  0  2      0 ",
]

const sorry2 = [
  "0  1  1      1 	Note	 	1	 	D♯2	 	  77	        1  0  0      0 ",
  "0  1  1      1 	Note	 	1	 	G3	 	  77	        1  0  0      0 ",
  "0  2  1      1 	Note	 	1	 	A♯2	 	  77	        1  0  0      0 ",
  "1  1  1      1 	Note	 	1	 	D2	 	  77	        1  0  0      0 ",
  "1  1  1      1 	Note	 	1	 	D3	 	  77	        1  0  0      0 ",
  "1  1  1      1 	Note	 	1	 	A3	 	  77	        1  0  0      0 ",
  "1  2  1      1 	Note	 	1	 	F3	 	  77	        1  0  0      0 ",
  "2  1  1      1 	Note	 	1	 	G1	 	  77	        1  0  0    98.",
  "2  2  1      1 	Note	 	1	 	D3	 	  77	        1  0  0      0 ",
  "2  2  1      1 	Note	 	1	 	F3	 	  77	        1  0  0      0 ",
  "2  3  1      1 	Note	 	1	 	A♯2	 	  77	        0  2  0      0 ",
  "3  3  1      1 	Note	 	1	 	A♯2	 	  77	        0  2  0      0 ",
  "3  3  3      1 	Note	 	1	 	D3	 	  77	        0  1  2      0 ",
  "3  4  1      1 	Note	 	1	 	F3	 	  77	        0  1  0      0 ",
  "3  4  3      1 	Note	 	1	 	A♯3	 	  77	        0  0  2      0 ",
  "4  1  1      1 	Note	 	1	 	D♯2	 	  77	        0  1  0      0 ",
  "4  1  1      1 	Note	 	1	 	G3	 	  77	        1  0  0      0 ",
  "4  1  1      1 	Note	 	1	 	A♯3	 	  37	        0  2  0      0 ",
  "4  2  1      1 	Note	 	1	 	D♯2	 	  77	        0  1  0      0 ",
  "4  2  1      1 	Note	 	1	 	G3	 	  77	        0  1  0      0 ",
  "4  3  1      1 	Note	 	1	 	D♯2	 	  77	        0  1  2      0 ",
  "4  3  3      1 	Note	 	1	 	A♯2	 	  77	        0  2  0      0 ",
  "4  3  3      1 	Note	 	1	 	G3	 	  77	        0  2  0      0 ",
  "4  3  3      1 	Note	 	1	 	G4	 	  37	        0  1  2      0 ",
  "4  4  3      1 	Note	 	1	 	D♯2	 	  77	        0  0  2      0 ",
  "4  4  3      1 	Note	 	1	 	F3	 	  77	        0  0  2      0 ",
  "5  1  1      1 	Note	 	1	 	D2	 	  77	        0  2  0      0 ",
  "5  1  1      1 	Note	 	1	 	F3	 	  77	        0  1  0      0 ",
  "5  1  1      1 	Note	 	1	 	A3	 	  77	        1  0  0      0 ",
  "5  1  3      1 	Note	 	1	 	A4	 	  37	        0  1  2      0 ",
  "5  2  1      1 	Note	 	1	 	F3	 	  77	        0  1  0      0 ",
  "5  3  1      1 	Note	 	1	 	D2	 	  77	        0  1  0      0 ",
  "5  3  1      1 	Note	 	1	 	F3	 	  77	        0  3  0      0 ",
  "5  4  1      1 	Note	 	1	 	D2	 	  77	        0  1  0      0 ",
  "5  4  1      1 	Note	 	1	 	F4	 	  37	        0  2  0      0 ",
  "5  4  3      1 	Note	 	1	 	A♯2	 	  77	        0  1  0      0 ",
  "6  1  1      1 	Note	 	1	 	G1	 	  77	        0  2  0      0 ",
  "6  1  1      1 	Note	 	1	 	D4	 	  37	        0  2  0      0 ",
  "6  2  1      1 	Note	 	1	 	D3	 	  77	        1  0  0      0 ",
  "6  2  1      1 	Note	 	1	 	F3	 	  77	        0  1  2      0 ",
  "6  3  1      1 	Note	 	1	 	G1	 	  77	        0  2  0    98.",
  "6  3  3      1 	Note	 	1	 	F2	 	  77	        0  0  3      0 ",
  "6  3  3      1 	Note	 	1	 	F3	 	  77	        0  0  3      0 ",
  "6  4  3      1 	Note	 	1	 	F2	 	  77	        0  0  3      0 ",
  "6  4  3      1 	Note	 	1	 	F3	 	  77	        0  0  3      0 ",
  "7  1  3      1 	Note	 	1	 	F2	 	  77	        0  0  3      0 ",
  "7  1  3      1 	Note	 	1	 	F3	 	  77	        0  0  3      0 ",
  "7  2  1      1 	Note	 	1	 	D4	 	  37	        0  1  0      0 ",
  "7  2  3      1 	Note	 	1	 	D♯4	 	  37	        0  1  0      0 ",
  "7  3  1      1 	Note	 	1	 	A♯1	 	  77	        0  2  0      0 ",
  "7  3  1      1 	Note	 	1	 	D4	 	  37	        0  1  0      0 ",
  "7  3  3      1 	Note	 	1	 	D2	 	  77	        0  1  2      0 ",
  "7  3  3      1 	Note	 	1	 	C4	 	  37	        0  1  0      0 ",
  "7  4  1      1 	Note	 	1	 	F2	 	  77	        0  1  0      0 ",
  "7  4  1      1 	Note	 	1	 	A♯3	 	  37	        0  1  0      0 ",
  "7  4  3      1 	Note	 	1	 	A♯2	 	  77	        0  0  2      0 ",
  "8  1  1      1 	Note	 	1	 	D♯2	 	  77	        0  1  0      0 ",
  "8  1  1      1 	Note	 	1	 	G3	 	  77	        1  0  0      0 ",
  "8  1  1      1 	Note	 	1	 	G4	 	  37	        0  2  0      0 ",
  "8  2  1      1 	Note	 	1	 	D♯2	 	  77	        0  1  0      0 ",
  "8  2  1      1 	Note	 	1	 	G3	 	  77	        0  1  0      0 ",
  "8  3  1      1 	Note	 	1	 	D♯2	 	  77	        0  1  2      0 ",
  "8  3  3      1 	Note	 	1	 	A♯2	 	  77	        0  2  0      0 ",
  "8  3  3      1 	Note	 	1	 	G3	 	  77	        0  2  0      0 ",
  "8  3  3      1 	Note	 	1	 	A♯3	 	  37	        0  1  2      0 ",
  "8  4  1      1 	Note	 	1	 	C4	 	  37	        0  1  2      0 ",
  "8  4  3      1 	Note	 	1	 	D♯2	 	  77	        0  0  2      0 ",
  "8  4  3      1 	Note	 	1	 	F3	 	  77	        0  0  2      0 ",
  "8  4  3      1 	Note	 	1	 	A3	 	  37	        0  1  2      0 ",
  "9  1  1      1 	Note	 	1	 	D2	 	  77	        0  2  0      0 ",
  "9  1  1      1 	Note	 	1	 	F3	 	  77	        0  1  0      0 ",
  "9  1  1      1 	Note	 	1	 	A3	 	  77	        1  0  0      0 ",
  "9  2  1      1 	Note	 	1	 	F3	 	  77	        0  1  0      0 ",
  "9  3  1      1 	Note	 	1	 	D2	 	  77	        0  1  0      0 ",
  "9  3  1      1 	Note	 	1	 	F3	 	  77	        0  3  0      0 ",
  "9  4  1      1 	Note	 	1	 	D2	 	  77	        0  1  0      0 ",
  "9  4  1      1 	Note	 	1	 	A♯3	 	  37	        0  2  0      0 ",
  "9  4  3      1 	Note	 	1	 	A♯2	 	  77	        0  1  0      0 ",
  "9  4  3      1 	Note	 	1	 	A♯3	 	  37	        0  2  0      0 ",
  "10  1  1      1 	Note	 	1	 	G1	 	  77	        0  2  0      0 ",
  "10  2  1      1 	Note	 	1	 	D3	 	  77	        1  0  0      0 ",
  "10  2  1      1 	Note	 	1	 	F3	 	  77	        0  1  2      0 ",
  "10  3  1      1 	Note	 	1	 	G1	 	  77	        0  2  0    98.",
  "10  3  3      1 	Note	 	1	 	F2	 	  77	        0  0  3      0 ",
  "10  3  3      1 	Note	 	1	 	F3	 	  77	        0  0  3      0 ",
  "10  4  3      1 	Note	 	1	 	F2	 	  77	        0  0  3      0 ",
  "10  4  3      1 	Note	 	1	 	F3	 	  77	        0  0  3      0 ",
  "11  1  3      1 	Note	 	1	 	F2	 	  77	        0  0  3      0 ",
  "11  1  3      1 	Note	 	1	 	F3	 	  77	        0  0  3      0 ",
  "11  3  1      1 	Note	 	1	 	A♯1	 	  77	        0  2  0      0 ",
  "11  3  3      1 	Note	 	1	 	D2	 	  77	        0  1  2      0 ",
  "11  4  1      1 	Note	 	1	 	F2	 	  77	        0  1  0      0 ",
  "11  4  3      1 	Note	 	1	 	A♯2	 	  77	        0  0  2      0 ",
]

let data = sorry2.join("\n");

export { data };
