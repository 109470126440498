<script>
  export let id = Math.random();
  export let label = '';
  export let placeholder = '';
  export let type = '';
  export let value = '';
  export let disabled = false;
</script>

<div>
  {#if label}
    <label for={id}>
      {label}
    </label>
  {/if}
  <input
    on:change
    on:input
    on:input={({ target }) => {
      value = target.value;
    }}
    on:focus
    on:blur
    on:paste
    {id}
    {placeholder}
    {type}
    {value}
    {disabled} />
</div>

<style>
  div {
    --em: 0.75rem;
    display: flex;
    align-items: baseline;
    padding: var(--baseline);
    position: relative;
    height: calc(var(--lineHeight) * 1.5);
  }

  div > :first-child {
    margin-right: var(--baseline);
  }

  label {
    display: block;
    height: var(--em);
    font-size: var(--em);
    line-height: var(--em);
    font-weight: 650;
    text-transform: uppercase;
    letter-spacing: 0.01em;
    color: hsl(var(--primary));
  }

  label:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  input {
    height: var(--em);
    font-size: var(--em);
    line-height: var(--em);
    color: hsl(var(--secondary));
    border-radius: 0;
    padding: 0;
    word-spacing: 1ch;
  }

  div:before {
    display: block;
    position: absolute;
    content: '';
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    transform: scale(0.5);
    box-shadow: inset 0 0 0 1px hsl(var(--focus-color, var(--quinary)));
    pointer-events: none;
  }

  div:focus-within {
    --focus-color: var(--accent);
  }

</style>
